import { useEffect, useState } from 'react';
import { useDispatch, RootStateOrAny } from 'react-redux';
import styled from 'styled-components';
import { __COLORS } from '../../Theme';
import { Button } from '../../../ui-library';
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useApi } from '../../api';
import { setUser } from '../../../redux/users/userSlice';
import { EXTRA_SMALL_DEVICES } from '../../helpers/Mobile';
import axios from 'axios';
// import { countryCodeList } from '../../options/countryCodeList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
// import ReactFlagsSelect from 'react-flags-select';
import { IsInputNumber } from '../../helpers/IsInputNumber';
import InputWrapper from '../../../ui-library/Input/InputWrapper';

const BodyContainer = styled.div`
  padding: 15px;
  ${EXTRA_SMALL_DEVICES`
 padding: 0px;
  `}
`;

const OtpTextDiv = styled.div`
  text-align: center;
  line-height: 3.4;
`;

const ErrorMessage = styled.div`
  color: red;
  height: 30px;
`;

const InputRow = styled.div`
  margin: 15px 0 5px 0;
`;

export const Label = styled.p`
  font-weight: 300;
  text-transform: uppercase;
  font-size: 14px;
  color: gray;
  width: 30%;
  ${EXTRA_SMALL_DEVICES`
  width: 50%;
  `}
`;

export const Value = styled.p`
  font-weight: 500;
  font-size: 16px;
`;

export const LabelValueWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const AlertMessage = styled.div`
  color: forestgreen;
  margin-bottom: 10px;
`;

const PopupHeadingdiv = styled.div`
  height: 4.87rem;
  padding: 1.31rem;
  border-bottom: 0.0625rem solid #e3dbdb;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PopupHeading = styled.h1`
  color: ${__COLORS.ICON_COLOR};
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const FieldLabel = styled.label`
  padding: 5px;
  margin-bottom: 10px;
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

const TimerWrapper = styled.div`
  text-align: center;
  color: ${__COLORS.RED_COLOR};
`;

type Props = {
  openVerificationModal: string;
  setOpenVerificationModal: (args: string) => void;
};

const VerificationModal = ({ openVerificationModal, setOpenVerificationModal }: Props) => {
  const api = useApi();
  const dispatch = useDispatch();
  const user = useSelector((s: RootStateOrAny) => s.users.user);

  const [otp, setOtp] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  // const [successMessage, setSuccessMessage] = useState('');
  const [details, setDetails] = useState('');

  // const [showInput, toggleInput] = useState(false);
  // const [phone, setPhone] = useState(user?.phone);
  // const [countryCode, setCountryCode] = useState('IN');

  const [alertMessage, setAlertMessage] = useState('');
  const [showForm, setShowForm] = useState(true);
  const [timer, setTimer] = useState(120);
  const [timerActive, setTimerActive] = useState(false);
  useEffect(() => {
    getVerificationStatus();
  }, [openVerificationModal]);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (timerActive) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (interval !== null) {
      clearInterval(interval);
    }

    if (timer === 0) {
      setTimerActive(false);
    }

    return () => {
      if (interval !== null) {
        clearInterval(interval);
      }
    };
  }, [timerActive, timer]);

  const getVerificationStatus = async () => {
    const userResponse = await api.users.getUser();
    dispatch(setUser(userResponse.data));

    if (openVerificationModal === 'EMAIL_VERIFICATION') {
      if (userResponse.data && userResponse.data.is_email_verified) {
        setAlertMessage(' Your Email is already Verified. Please Enjoy the Service!');
        setShowForm(false);
      } else {
        sendVerifyOtp();
      }
    } else if (openVerificationModal === 'PHONE_VERIFICATION') {
      // Object.entries(countryCodeList).map(([key, value]) => {
      //   if (user) {
      //     if (user?.country_Code === value.primary) {
      //       setCountryCode(key);
      //     }
      //   }
      // });
      if (userResponse.data && userResponse.data.is_phone_verified) {
        setAlertMessage(' Your Phone Number is already Verified. Please Enjoy the Service!');
        setShowForm(false);
      } else {
        sendVerifyOtp();
      }
    }
  };

  const sendVerifyOtp = async () => {
    try {
      const payload = {
        verificationMethod: openVerificationModal === 'EMAIL_VERIFICATION' ? 'email' : 'phone',
      };
      await api.users.sendOtp(payload);
      startTimer();
      toast.success('OTP has been sent successfully!');
    } catch (e) {
      setErrorMessage((e as Error).message);
    }
  };
  const startTimer = () => {
    setTimer(120); // Reset timer to 2 minutes
    setTimerActive(true);
  };

  const resendOtp = async () => {
    setErrorMessage('');
    try {
      if (openVerificationModal === 'EMAIL_VERIFICATION') {
        await api.users.getEmailVerificationLink();
      } else if (openVerificationModal === 'PHONE_VERIFICATION') {
        const response = await api.users.getPhoneVerificationLink();
        const details = response && response.Details ? response.Details : '';
        setDetails(details);
      }
      startTimer();
      toast.success('OTP has been sent successfully!');
    } catch (e) {
      setErrorMessage((e as Error).message);
    }
  };

  // const saveDetails = async () => {
  //   try {
  //     let selectedCountryCode = '';
  //     Object.entries(countryCodeList).map(([key, value]) => {
  //       if (countryCode === key) {
  //         selectedCountryCode = value.primary;
  //       }
  //     });
  //     await api.users.update({ phone, country_Code: selectedCountryCode });
  //     const response = await api.users.getUser();
  //     dispatch(setUser(response.data));
  //     toast.success('Phone Number updated successfully.');
  //     toggleInput(false);
  //   } catch (e) {
  //     toast.error('Something went wrong!');
  //   }
  // };

  const verifyOtp = async () => {
    setErrorMessage('');
    try {
      if (validateOTP()) {
        if (openVerificationModal === 'PHONE_VERIFICATION') {
          const secretValue = !details ? user?.otp_secret || '' : details;
          const otpResponse = await axios.get(
            `https://2factor.in/API/V1/e5b4e95d-6620-11ec-b710-0200cd936042/SMS/VERIFY/${secretValue}/${otp}`,
          );

          if (otpResponse && otpResponse.data && otpResponse.data.Details == 'OTP Matched') {
            await api.users.verifyOtp({ otp: otp });
          }
        } else {
          await api.users.verifyOtp({ otp: otp });
        }
        const userResponse = await api.users.getUser();
        dispatch(setUser(userResponse.data));
        toast.success('Verified successfully!');
        setOpenVerificationModal('');
      }
    } catch (e: any) {
      toast.error(e.message || 'Wrong OTP');
    }
  };

  const validateOTP = () => {
    if (otp.replace(/\s/g, '').length < 4) {
      setErrorMessage('Please enter valid OTP!');
      return false;
    } else {
      return true;
    }
  };

  const handleInput = (value: any) => {
    if (IsInputNumber(value)) {
      setOtp(value);
    }
  };

  // const handlePhoneInput = (value: any) => {
  //   if (IsInputNumber(value)) {
  //     setPhone(value);
  //   }
  // };

  // const onCodeSelect = (e: any) => {
  //   // const formatVal = formatPhoneNumber(e);
  //   // setPhoneNumber(e);
  //   setCountryCode(e);
  // };

  return (
    <Modal
      isOpen={openVerificationModal === 'PHONE_VERIFICATION' || openVerificationModal === 'EMAIL_VERIFICATION'}
      style={{ marginTop: '45px' }}
    >
      <PopupHeadingdiv>
        <PopupHeading>{`Verify ${
          openVerificationModal === 'PHONE_VERIFICATION' ? 'Phone Number' : 'Email'
        }`}</PopupHeading>
        <FontAwesomeIcon icon={faXmark} onClick={() => setOpenVerificationModal('')} style={{ cursor: 'pointer' }} />
      </PopupHeadingdiv>
      <ModalBody>
        <BodyContainer>
          {alertMessage !== '' && <AlertMessage>{alertMessage}</AlertMessage>}
          {showForm ? (
            <>
              {openVerificationModal === 'EMAIL_VERIFICATION' && (
                <InputRow>
                  <FieldLabel>Enter the OTP sent to your email address.</FieldLabel>
                  <InputWrapper
                    placeholder="Enter OTP"
                    type="text"
                    onChange={(e: any) => handleInput(e.target.value)}
                    value={otp}
                    required
                    maxLength="6"
                    width={'100%'}
                    style={{ height: '50px' }}
                  />
                </InputRow>
              )}
              {openVerificationModal === 'PHONE_VERIFICATION' && (
                <>
                  {/* {showInput ? (
                    <>
                      <InputRow style={{ marginTop: '0px' }}>
                        <FieldLabel>Phone</FieldLabel>
                        <PhoneInputWrapper>
                          <ReactFlagsSelect
                            selected={countryCode}
                            onSelect={onCodeSelect}
                            showSelectedLabel={true}
                            showOptionLabel={true}
                            customLabels={countryCodeList}
                            placeholder="Country Code "
                            searchable
                            countries={['JM', 'BB', 'GU', 'GD', 'MP', 'MS', 'BM']}
                            blacklistCountries
                            selectedSize={14}
                            optionsSize={14}
                            selectButtonClassName={styles.codeInputButton}
                            className={styles.codeInputWrapper}
                          />
                         
                          <PhoneInputDiv>
                            <Input
                              placeholder="XXXXX XXXXX"
                              type="text"
                              onChange={(e: any) => handlePhoneInput(e.target.value)}
                              value={phone}
                              maxLength="10"
                            />
                          </PhoneInputDiv>
                        </PhoneInputWrapper>
                      </InputRow>
                      <ButtonContainer>
                        <Button isSecondary style={{ marginRight: SPACING }} onClick={() => toggleInput(false)}>
                          Cancel
                        </Button>
                        <Button onClick={saveDetails}>Save</Button>
                      </ButtonContainer>
                    </>
                  ) : (
                    <LabelValueWrapper>
                      <FieldLabel>Phone Number : </FieldLabel>

                      <FieldLabel>
                        {user?.country_Code}
                        {phone}
                      </FieldLabel>
                      <IconWrapper onClick={() => toggleInput(true)}>
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </IconWrapper>
                    </LabelValueWrapper>
                  )} */}

                  <InputRow>
                    <FieldLabel>Enter the OTP sent to your registered number.</FieldLabel>
                    <InputWrapper
                      placeholder="Enter OTP"
                      type="text"
                      onChange={(e: any) => handleInput(e.target.value)}
                      value={otp}
                      required
                      maxLength="6"
                      width={'100%'}
                      style={{ height: '50px' }}
                    />
                  </InputRow>
                </>
              )}
              <ErrorMessage>{`${errorMessage}`}</ErrorMessage>
              {timerActive ? (
                <TimerWrapper>
                  Resend OTP in {Math.floor(timer / 60)}:{timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
                </TimerWrapper>
              ) : (
                <>
                  <OtpTextDiv>Didn't receive OTP?</OtpTextDiv>
                  <ButtonWrapper>
                    <Button isPrimary onClick={resendOtp}>
                      Resend OTP
                    </Button>
                  </ButtonWrapper>
                </>
              )}
            </>
          ) : null}
        </BodyContainer>
      </ModalBody>
      <ModalFooter>
        {showForm ? (
          <>
            <Button onClick={() => setOpenVerificationModal('')} isSecondary>
              Cancel
            </Button>
            <Button onClick={() => verifyOtp()}>Verify OTP</Button>
          </>
        ) : (
          <Button onClick={() => setOpenVerificationModal('')} isSecondary>
            Close
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default VerificationModal;
