import styled from 'styled-components';
import { Button } from '../../ui-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faMagnifyingGlass, faIndianRupeeSign, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { __COLORS } from '../../shared/Theme';
import { useEffect, useState } from 'react';
import Loading from '../Loading/Loading';
import { SPACING } from '../../shared/Layout';
import { useApi } from '../../shared/api';
import { toast } from 'react-toastify';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import useDelay from '../../shared/hooks/useDelay';
import { Pagination } from '../../ui-library';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const TableWrapperDiv = styled.div`
  overflow-x: auto;
  max-height: 500px;
  overflow-y: auto;
`;

const BillingTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  & td {
    background: ${__COLORS.WHITE_COLOR};
    padding: 0.625rem 0 0.625rem 0.625rem;
    border-bottom: 0.125rem solid;
    @media (min-width: 320px) and (max-width: 1200px) {
      padding: 0.313rem 0 0.313rem 0.313rem;
    }
  }
`;

const TableHeading = styled.th`
  width: 16%;
  border: 3px solid #f5f5f7;
  font-size: 1rem;
  font-family: Poppins;
  font-weight: 500;
  color: ${__COLORS.ICON_COLOR};
  background: ${__COLORS.WHITE_COLOR};
  height: 5.0625rem;
  flex-shrink: 0;
  border-radius: 0.9375rem 0.9375rem 0rem 0rem;
  white-space: nowrap;
  padding: 0 10px;
  @media (min-width: 320px) and (max-width: 1200px) {
    font-size: 0.938rem;
    height: 4.0625rem;
  }
`;

const HeaderRow = styled.tr`
  border: 0.125rem solid #614be2;
`;

const ButtonContainer = styled.div`
  text-align: -webkit-center;
`;

const TransactionFilterContainer = styled.div`
  display: flex;
  width: 60%;
  align-items: center;
`;

const TableRow = styled.tr`
  height: 6.625rem;
  border: 0.188rem solid #f5f5f7;
  @media (min-width: 320px) and (max-width: 1200px) {
    height: 5.625rem;
  }
`;

const InnerTableData = styled.div<{ showBorder: boolean }>`
  border-right: ${(props) => (props.showBorder ? '0.03125rem solid #303c4e' : 'none')};
  height: 4.26031rem;
  align-items: center;
  display: flex;
  justify-content: center;
  @media (min-width: 320px) and (max-width: 786px) {
    height: 3.26031rem;
  }
`;

const Tdata = styled.h1`
  color: ${__COLORS.ICON_COLOR};
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 20px;
  @media (min-width: 320px) and (max-width: 1200px) {
    font-size: 0.875rem;
    padding: 0 10px;
  }
`;

const InputWithIcon = styled.div`
  display: flex;
  width: 300px;
  align-items: center;
  border: none;
  padding: 0.5rem;
  background: ${__COLORS.WHITE_COLOR};
  border-radius: 0.3125rem !important;
`;

const InputSearch = styled.input`
  flex: 1;
  border: none;
  outline: none;
  background: ${__COLORS.WHITE_COLOR};
  width: 100%;
`;

const NoRecordsText = styled.div`
  text-align: center;
  margin: ${SPACING * 2}px 0;
  width: 100%;
  color: ${__COLORS.SECONDARY_DARK};
`;

const StatusText = styled.div<{ isSuccess?: boolean }>`
  color: ${({ isSuccess }) => (isSuccess ? __COLORS.GREEN_COLOR : __COLORS.RED_COLOR)};
`;

type Props = {
  calledFrom?: string;
};

const BillingHistory = ({ calledFrom = 'USER' }: Props) => {
  const api = useApi();

  const [isLoading, setLoading] = useState(true);
  const [billingHistory, setBillingHistory] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [csvData, setCsvData] = useState<any>([]);
  const [searchTransaction, setSearchTransaction] = useState('');
  const [selectedFilter, setSelectedFilter] = useState({
    name: '',
    order: true,
  });
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchTxn = useDelay(searchValue, 700);

  const headers = [
    { label: 'SL', key: 'id' },
    { label: 'TRANSACTION ID', key: 'transactionId' },
    { label: 'DATE & TIME', key: 'dateTime' },
    { label: 'AMOUNT', key: 'amount' },
  ];

  useEffect(() => {
    getBillingHistory();
  }, []);

  useEffect(() => {
    createCsvData();
  }, [billingHistory]);

  useEffect(() => {
    if (debouncedSearchTxn) {
      setSearchTransaction(debouncedSearchTxn);
      setCurrentPage(1);
    } else {
      setSearchTransaction('');
      setCurrentPage(1);
    }
  }, [debouncedSearchTxn]);

  useEffect(() => {
    getBillingHistory();
  }, [currentPage, selectedFilter, searchTransaction]);

  const raiseDispute = async (txnId: string) => {
    try {
      const response = await api.billing.raiseDispute({ requestId: txnId });
      toast.success(response.message);

      setLoading(false);
    } catch (e) {
      toast.error((e as Error).message);
      setLoading(false);
    }
  };

  const createCsvData = () => {
    const tempList: any = [];

    billingHistory.map((item: any) => {
      tempList.push({
        id: item.id,
        transactionId: item.txnid,
        dateTime: item.order_date ? moment(item.order_date).format('DD MMM, YYYY, hh:mm A') : '-',
        amount: item?.linked_subscription?.value || '-',
      });
    });

    setCsvData(tempList);
  };

  const getBillingHistory = async () => {
    try {
      if (calledFrom === 'ADMIN') {
        const response = await api.admin.getTransactions({
          searchTerm: searchTransaction,
          pageNo: currentPage,
          sortedTerm: selectedFilter.name,
          isAscending: selectedFilter.order,
        });
        if (response) {
          setTotalCount(response.length);
          setBillingHistory(response);
        }
      } else {
        const response = await api.billing.getBillingHistory({
          searchTerm: searchTransaction,
          pageNo: currentPage,
          sortedTerm: selectedFilter.name,
          isAscending: selectedFilter.order,
        });
        if (response.allOrders) {
          setTotalCount(response.allOrders?.count);
          setBillingHistory(response.allOrders?.data);
        }
      }

      setLoading(false);
    } catch (e) {
      toast.error((e as Error).message);
      setLoading(false);
    }
  };

  const handleFilter = (column: string, value: boolean) => {
    setSelectedFilter({ name: column, order: value });
  };

  return (
    <>
      <HeaderContainer>
        <TransactionFilterContainer>
          <InputWithIcon>
            <FontAwesomeIcon icon={faMagnifyingGlass} style={{ marginRight: '0.5rem' }} size="sm" />
            <InputSearch
              type="text"
              id="searchText"
              name="searchText"
              placeholder="TRANSACTION ID"
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              autoComplete="off"
            />
          </InputWithIcon>
        </TransactionFilterContainer>
        <Button isPrimary disabled={!billingHistory.length}>
          <CSVLink
            data={csvData}
            headers={headers}
            filename={'Billing-History.csv'}
            style={{ color: `${__COLORS.WHITE_COLOR}` }}
          >
            Export Data
          </CSVLink>
        </Button>
      </HeaderContainer>

      <TableWrapperDiv>
        <BillingTable>
          <HeaderRow>
            {calledFrom === 'ADMIN' && <TableHeading>USER NAME</TableHeading>}
            {calledFrom === 'ADMIN' && <TableHeading>USER EMAIL</TableHeading>}

            <TableHeading>TRANSACTION ID</TableHeading>
            <TableHeading>
              {`DATE & TIME `}
              {selectedFilter.order ? (
                <FontAwesomeIcon
                  icon={faChevronUp}
                  onClick={() => handleFilter('order_date', false)}
                  style={{ cursor: 'pointer' }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faChevronDown}
                  onClick={() => handleFilter('order_date', true)}
                  style={{ cursor: 'pointer' }}
                />
              )}{' '}
            </TableHeading>
            <TableHeading>AMOUNT</TableHeading>

            <TableHeading>ACTION</TableHeading>
          </HeaderRow>
          {billingHistory?.length
            ? billingHistory.map((item: any) => (
                <TableRow key={item.id}>
                  {calledFrom === 'ADMIN' && (
                    <td>
                      <InnerTableData showBorder>
                        <Tdata>{`${item?.linked_user?.first_name || ''} ${item?.linked_user?.last_name || ''}`}</Tdata>
                      </InnerTableData>
                    </td>
                  )}
                  {calledFrom === 'ADMIN' && (
                    <td>
                      <InnerTableData showBorder>
                        <Tdata>{item?.linked_user?.email || '-'}</Tdata>
                      </InnerTableData>
                    </td>
                  )}
                  <td>
                    <InnerTableData showBorder>
                      <Tdata>{item.txnid}</Tdata>
                    </InnerTableData>
                  </td>
                  <td>
                    <InnerTableData showBorder>
                      <Tdata>{item.updatedAt ? moment(item.updatedAt).format('DD MMM, YYYY, hh:mm A') : '-'}</Tdata>
                    </InnerTableData>
                  </td>
                  <td>
                    <InnerTableData showBorder>
                      <Tdata>
                        <FontAwesomeIcon icon={faIndianRupeeSign} style={{ marginRight: '5px' }} />
                        {item?.amount || '-'}
                      </Tdata>
                    </InnerTableData>
                  </td>
                  <td>
                    <InnerTableData showBorder={false}>
                      <Tdata>
                        {item.order_status === 'FAILURE' ? (
                          <ButtonContainer>
                            <StatusText>Failed</StatusText>
                            <Button onClick={() => raiseDispute(item.txnid)} isPrimary>
                              Raise Dispute{' '}
                            </Button>
                          </ButtonContainer>
                        ) : item.order_status === 'DISPUTE_RAISED' ? (
                          <StatusText>Dispute Raised</StatusText>
                        ) : (
                          <StatusText isSuccess>Success</StatusText>
                        )}
                      </Tdata>
                    </InnerTableData>
                  </td>
                </TableRow>
              ))
            : null}
        </BillingTable>
      </TableWrapperDiv>
      {billingHistory?.length ? <Pagination totalCount={totalCount} onChangeHandler={setCurrentPage} /> : null}
      <>{isLoading ? <Loading /> : !billingHistory?.length ? <NoRecordsText>No records found</NoRecordsText> : null}</>
    </>
  );
};

export default BillingHistory;
